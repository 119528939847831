import React from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import "../../styles/Home.css";
import aboutus_main from "../../Assets/aboutus_main.png";
import aboutus_sec1 from "../../Assets/aboutus_sec1.png";
import aboutus_sec2 from "../../Assets/aboutus_sec2.png";
import about_us from "../../Assets/about_us.png";
import { try_for_free } from "../../url";
import Map from "./map";

const buttonStyles = {
  backgroundColor: "#25B2E8",
  fontFamily: "Roboto",
  height: "45px",
  fontWeight: "bold",
  borderRadius: "10px",
  fontSize: { xs: "14px", md: "18px" },
  color: "white",
  textTransform: "capitalize",
  width: { xs: "130px", md: "160px" },
  "&:hover": {
    backgroundColor: "#25B2E8",
  },
};

const sectionTypographyStyles = {
  fontFamily: "Roboto",
  lineHeight: { xs: "30px", md: "35px" },
  fontWeight: "normal",
  fontSize: { xs: "15px", md: "18px" },
  color: "#6E6B7B",
};

const AboutUs = () => (
  <>
    {/* Main Hero Section */}
    <Box mt={{ xs: 7, md: 7 }}>
      <Box
        pt={{ xs: 10, sm: 15, md: 20 }}
        pb={{ xs: 4, md: 10 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${about_us})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
          height: { xs: "auto", sm: "auto", md: "10vh" },
        }}
      >
        <Grid container spacing={0}>
          <Grid xs={12} md={12}>
            <Stack direction="column" alignItems="center">
              <Typography
                variant="paragraph"
                align="center"
                sx={{
                  fontFamily: "Roboto",
                  lineHeight: { xs: "60px", md: "70px" },
                }}
                fontWeight="bold"
                fontSize={{ xs: "40px", md: "75px" }}
                color="#1662A7"
              >
                About
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>

    {/* About Us Section */}
    <Box pb={{ xs: 3, sm: 10, md: 13 }} sx={{ backgroundColor: "#F8F8F8" }}>
      <Container
        maxWidth="lg"
        sx={{
          maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "80%", xl: "75%" },
        }}
      >
        <Grid container spacing={0} pt={{ xs: 2, md: 10 }}>
          <Grid xs={12} sm={6} md={6}>
            <Stack direction="column" spacing={{ xs: 1, md: 2 }}>
              <Typography
                variant="paragraph"
                fontWeight="bold"
                fontSize={{ xs: "35px", md: "65px" }}
                color="#1662A7"
              >
                RequireSign
              </Typography>

              <Typography
                variant="paragraph"
                sx={{
                  color: "#6E6B7B",
                  fontSize: "25px",
                  fontWeight: "medium",
                }}
              >
                Simplifying&nbsp;
                <span
                  style={{
                    color: "#25B2E8",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  Digital Signatures
                </span>
              </Typography>

              <Typography variant="paragraph" sx={sectionTypographyStyles}>
                For decades, e-signatures have transformed the way businesses
                operate globally. As the market has grown, so too has the need
                for a more accessible and affordable solution.
              </Typography>

              <Typography variant="paragraph" sx={sectionTypographyStyles}>
                That's where RequireSign comes in.
              </Typography>

              <a href={try_for_free} target="_blank" rel="noreferrer">
                <Button sx={buttonStyles}>Try for FREE</Button>
              </a>
            </Stack>
          </Grid>

          <Grid xs={12} sm={6} md={6} align="center" pt={{ xs: 4, md: 4 }}>
            <Box
              component="img"
              src={aboutus_main}
              sx={{ display: "block", width: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>

    {/* Section 1 & 2 */}
    <Box pt={3} pb={5}>
      <Container
        maxWidth="lg"
        sx={{
          maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "80%", xl: "75%" },
        }}
      >
        <Grid container spacing={0}>
          {/* Section 1 */}
          <Grid xs={12} sm={6} md={6}>
            <Box
              component="img"
              src={aboutus_sec1}
              sx={{ pt: { xs: 5, md: 0 }, width: { xs: "100%", md: "85%" } }}
            />
          </Grid>

          <Grid xs={12} sm={6} md={6}>
            <Stack sx={{ height: "90%", justifyContent: "center" }}>
              <Typography variant="paragraph" sx={sectionTypographyStyles}>
                Our team of seasoned tech experts has a proven track record of
                delivering innovative solutions for leading corporations
                worldwide. By leveraging our deep understanding of both business
                and technology, we've developed an e-signature platform that's
                not only powerful but also easy to use.
              </Typography>
            </Stack>
          </Grid>

          {/* Section 2 */}
          <Grid xs={12} sm={6} md={6}>
            <Box
              component="img"
              src={aboutus_sec2}
              sx={{ pt: { xs: 5, md: 0 }, width: { xs: "100%", md: "85%" } }}
            />
          </Grid>

          <Grid xs={12} sm={6} md={6}>
            <Stack sx={{ height: "95%", justifyContent: "center" }}>
              <Typography variant="paragraph" sx={sectionTypographyStyles}>
                Whether you're a small local business or a large global
                enterprise, RequireSign offers a cost-effective solution that
                empowers you to streamline your processes, reduce paperwork, and
                enhance security. Join us in embracing the future of digital
                signatures.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>

    <Map />
  </>
);

export default AboutUs;

import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import "../../styles/Home.css";

import budget_concious from "../../Assets/budget_concious.png";
import full_features from "../../Assets/full_features.png";
import legally_binding from "../../Assets/legally_binding.png";
import encruption from "../../Assets/encruption.png";
import planet_friendly from "../../Assets/planet_friendly.png";

const cardData = [
    { img: budget_concious, title: "Budget\nConcious" },
    { img: full_features, title: "Full\nFeatures" },
    { img: legally_binding, title: "Legally\nBinding" },
    { img: encruption, title: "Secured\nEncryption" },
    { img: planet_friendly, title: "Planet\nFriendly" },
];

const CardComponent = ({ img, title }) => (
    <Grid item xs={12} md={2.4} p={1}>
        <Card
            sx={{
                cursor: "pointer",
                width: "100%",
                borderRadius: "10px",
                backgroundColor: "#F3F4F6",
                border: "1px solid #B8C2CC",
                boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s ease",
                '&:hover': {
                    backgroundColor: "#25B2E8",
                    '.hover-text': {
                        color: "white",
                    },
                },
            }}
        >
            <CardContent>
                <Stack direction="column" spacing={2} pl={5} pr={5}>
                    <Box
                        component="img"
                        src={img}
                        sx={{ alignSelf: "center", width: 100, height: 100 }}
                    />
                    <Typography
                        className="hover-text"
                        sx={{
                            fontFamily: "Roboto",
                            fontWeight: "medium",
                            fontSize: { xs: "20px", md: "20px" },
                            color: "#1662A7",
                            transition: "color 0.3s ease, text-align 0.3s ease",
                            textAlign: "center",
                        }}
                    >
                        {title}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    </Grid>
);

function Cards() {
    return (
        <Box pt={{ xs: 10, md: 10 }} pb={{ xs: 10, md: 10 }}>
            <Grid container spacing={0}>
                {cardData.map((data, index) => (
                    <CardComponent key={index} img={data.img} title={data.title} />
                ))}
            </Grid>
        </Box>
    );
}

export default Cards;

import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import "../../styles/Home.css";
import add_file from "../../Assets/add_file.png";
import template from "../../Assets/template.png";
import links from "../../Assets/links.png";

const FeatureCard = ({ src, text }) => (
    <Grid item xs={12} md={4}>
        <Stack alignItems="center">
            <Box component="img" src={src} sx={{ width: "95%" }} />
            <Typography
                sx={{
                    fontFamily: "Roboto",
                    fontWeight: "medium",
                    fontSize: { xs: "14px", md: "21px" },
                    color: "#25B2E8",
                    textAlign: "center",
                }}
            >
                {text}
            </Typography>
        </Stack>
    </Grid>
);

function UploadingAndFormatting() {
    const features = [
        { src: add_file, text: "Uploading existing PDF files" },
        { src: template, text: "Assign areas to complete" },
        { src: links, text: "Create public web link" },
    ];

    return (
        <Box pt={5} pb={5}>
            <Container
                maxWidth="lg"
                sx={{
                    maxWidth: {
                        xs: '100%',
                        sm: '90%',
                        md: '80%',
                        lg: '80%',
                        xl: '75%',
                    }
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} align="center" pb={2}>
                        <Typography
                            sx={{
                                width: { xs: "100%", md: "90%" },
                                fontFamily: "Roboto",
                                lineHeight: "45px",
                                fontWeight: "medium",
                                fontSize: { xs: "22px", md: "40px" },
                                color: "#1662A7",
                            }}
                        >
                            Uploading & Formatting Documents
                        </Typography>
                    </Grid>
                    {features.map((feature, index) => (
                        <FeatureCard key={index} {...feature} />
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default UploadingAndFormatting;

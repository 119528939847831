import { Avatar, Box, Button, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import "../../styles/Home.css";
import data_center_1 from "../../Assets/data_center_1.png";
import data_center_2 from "../../Assets/data_center_2.png";
import data_center_3 from "../../Assets/data_center_3.png";
import data_center_4 from "../../Assets/data_center_4.png";
import data_center_5 from "../../Assets/data_center_5.png";
import data_center_6 from "../../Assets/data_center_6.png";
import data_center_7 from "../../Assets/data_center_7.png";
import data_center_8 from "../../Assets/data_center_8.png";
import data_center_9 from "../../Assets/data_center_9.png";
import data_center_10 from "../../Assets/data_center_10.png";
import Textfield from '../Textfield';
import Slider from "react-slick";
import "./Testmonials.css";
import "aos/dist/aos.css";

function DataCenters() {

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: true,
        centerPadding: "0px",
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 366,
                settings: {
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    centerPadding: "0px",
                    slidesToScroll: 1
                }
            }
        ]
    };

    const images = [
        { src: data_center_1, width: { xs: "40%", md: "50%" } },
        { src: data_center_2, width: { xs: "40%", md: "60%" } },
        { src: data_center_3, width: { xs: "40%", md: "40%" } },
        { src: data_center_4, width: { xs: "40%", md: "60%" } }
    ];

    const sliderImages = [
        { src: data_center_5, width: { xs: "40%", md: "40%" } },
        { src: data_center_6, width: { xs: "40%", md: "55%" } },
        { src: data_center_7, width: { xs: "40%", md: "35%" } },
        { src: data_center_8, width: { xs: "40%", md: "50%" }, pt: 3 },
        { src: data_center_9, width: { xs: "40%", md: "60%" } },
        { src: data_center_10, width: { xs: "40%", md: "60%" } }
    ];

    return (
        <Box pb={4}>
            <Box pl={{ xs: 5, md: 25 }} pr={{ xs: 5, md: 25 }}>
                <Grid container spacing={0}>
                    {/* Data Center Images */}
                    {images.map((image, index) => (
                        <Grid item xs={12} sm={3} md={3} pt={2} align="center" key={index}>
                            <Box component="img" src={image.src} sx={{ width: image.width }} />
                        </Grid>
                    ))}

                    {/* Title Text */}
                    <Grid xs={12} md={12} pt={7} pb={7} align="center">
                        <Box sx={{ width: "100%" }}>
                            <Typography variant="paragraph" sx={{ alignSelf: "center", fontFamily: "Roboto", lineHeight: { xs: "40px", md: "50px" } }} fontWeight="bold" fontSize={{ xs: "20px", md: "30px" }} color="#6E6B7B">
                                RequireSign adheres to<br /> <span style={{ fontWeight: 900, color: "#1662A7", textTransform: "capitalize" }}>GLOBAL COMPLIANCE STANDARDS</span> for <span style={{ fontWeight: 900, color: "#1662A7", textTransform: "capitalize" }}>PRIVACY OF SENSITIVE INFORMATION</span>
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Slider Images */}
                    <Grid item xs={12} md={12} lg={12} align="center">
                        <Box align="center">
                            <Box pl={{ xs: 0, md: 0 }} pt={5}>
                                <Slider autoplay {...settings}>
                                    {sliderImages.map((image, index) => (
                                        <div key={index}>
                                            <Box
                                                component="img"
                                                src={image.src}
                                                sx={{ width: image.width, pt: image.pt || 0 }}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Textfield */}
                    <Grid item xs={12} sm={12} md={12} lg={12} pt={4} pb={4} align="center">
                        <Textfield />
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
}

export default DataCenters;

import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import "../../styles/Home.css";
import rebranding_and_white_labling from "../../Assets/rebranding_and_white_labling.png";
import UploadingAndFormatting from "./uploadinfAndformating";
import Functions from "./functions";
import { try_for_free } from "../../url";
import features from "../../Assets/features.png";

function Features() {
  const styles = {
    heading: {
      fontFamily: "Roboto",
      lineHeight: { xs: "50px", md: "70px" },
      fontWeight: "bold",
      fontSize: { xs: "40px", md: "70px" },
      color: "#1662A7",
    },
    subHeading: {
      fontFamily: "Roboto",
      lineHeight: "45px",
      fontWeight: "medium",
      fontSize: { xs: "22px", md: "40px" },
      color: "#1662A7",
    },
    textContent: {
      fontFamily: "Roboto",
      lineHeight: "35px",
      fontWeight: 450,
      fontSize: { xs: "15px", md: "17px" },
      color: "#6E6B7B",
    },
    button: {
      mt: 2,
      backgroundColor: "#25B2E8",
      fontFamily: "Roboto",
      height: "40px",
      fontWeight: "bold",
      borderRadius: "10px",
      fontSize: { xs: "14px", md: "16px" },
      color: "white",
      textTransform: "capitalize",
      width: { xs: "130px", md: "150px" },
      "&:hover": { backgroundColor: "#25B2E8" },
    },
  };

  return (
    <>
      {/* Features Section */}
      <Box mt={7}>
        <Box
          pt={{ xs: 1, sm: 5, md: 20 }}
          pb={{ xs: 1, sm: 5, md: 10 }}
          sx={{
            backgroundImage: `url(${features})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // start background from bottom of the box
            backgroundPosition: "center",
            width: "100%",
            height: { xs: "auto", md: "10vh" },
          }}
        >
          <Container maxWidth="lg" sx={{ textAlign: "center" }}>
            <Typography variant="h1" sx={styles.heading}>
              Features
            </Typography>
          </Container>
        </Box>
      </Box>

      {/* Rebranding Section */}
      <Box pt={5} pb={5}>
        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          <Typography variant="paragraph" sx={styles.subHeading}>
            Rebranding & White Labeling
          </Typography>

          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            mt={5}
          >
            {/* Image */}
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={rebranding_and_white_labling}
                sx={{ width: "95%", display: "block", margin: "0 auto" }}
              />
            </Grid>

            {/* Content */}
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{ textAlign: "left", px: { xs: 0, md: 10 } }}
              >
                <Typography sx={styles.textContent}>
                  Tailor your experience with our Rebranding & White Labeling
                  features. Professional and Team plans offer logo
                  customization, while Enterprise and On-Prem take it to the
                  next level with custom domain names.
                  <br />
                  <br />
                  Example of custom domain:
                  <br />
                  <span style={{ fontWeight: 900, color: "#222222" }}>
                    esign.YourCompany.com
                  </span>
                </Typography>
                <Button
                  component="a"
                  href={try_for_free}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={styles.button}
                >
                  Try for FREE
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Additional Components */}
      <UploadingAndFormatting />
      <Functions />
    </>
  );
}

export default Features;

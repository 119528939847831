import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import animated_map from "../../Assets/animated_map.gif";
import "../../styles/Home.css";
import Textfield from "../Textfield";

function Map() {
  const styles = {
    highlightedText: {
      fontWeight: "bold",
      color: "#1662A7",
      textTransform: "capitalize",
    },
    sectionText: {
      alignSelf: "center",
      fontFamily: "Roboto",
    },
    regionText: {
      alignSelf: "center",
      fontFamily: "Roboto",
      color: "#25B2E8",
    },
    mapImage: {
      alignSelf: "center",
      width: { xs: "100%", md: "90%" },
    },
  };

  return (
    <Box pt={5} pb={3}>
      <Box px={{ xs: 5, md: 20 }}>
        <Stack direction="column" spacing={4} alignItems="center">
          <Typography
            variant="paragraph"
            sx={{ ...styles.sectionText, lineHeight: "45px" }}
            fontWeight="medium"
            fontSize={{ xs: "15px", md: "30px" }}
            color="#6E6B7B"
          >
            Clients can be hosted{" "}
            <span style={styles.highlightedText}>ON-PREMISE</span> or on{" "}
            <span style={styles.highlightedText}>OUR CLOUD SERVERS</span> located in
          </Typography>

          <Typography
            variant="paragraph"
            sx={{ ...styles.regionText, lineHeight: "35px" }}
            fontWeight="medium"
            fontSize={{ xs: "15px", md: "40px" }}
          >
            US, CAN, LATAM, UK, EU, ASEAN and OTHER REGIONS
          </Typography>
        </Stack>

        <Box component="img" src={animated_map} sx={styles.mapImage} />

        <Box pb={2}>
          <Textfield />
        </Box>
      </Box>
    </Box>
  );
}

export default Map;

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../../styles/Home.css";
import contact_us from "../../Assets/contact_us.png";
import it_support from "../../Assets/it_support.png";
import Inputfield from "../inputfield";
import { useFormik } from "formik";
import * as yup from "yup";
import Textfield from "../Textfield";

function Contactus() {
  const validationSchema = yup.object({
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    businessemail: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    companyname: yup.string().required("Company Name is required"),
    jobtitle: yup.string().required("Job Title is required"),
    companysize: yup.string().required("Company Size is required"),
    comments: yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      businessemail: "",
      companyname: "",
      jobtitle: "",
      companysize: "",
      comments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const inputFields = [
    { label: "First Name*", name: "firstname", type: "text" },
    { label: "Last Name*", name: "lastname", type: "text" },
    { label: "Business Email*", name: "businessemail", type: "email" },
    { label: "Company Name*", name: "companyname", type: "text" },
    { label: "Job Title*", name: "jobtitle", type: "text" },
    { label: "Company Size*", name: "companysize", type: "number" },
    {
      label: "Questions or comments (optional)",
      name: "comments",
      type: "textarea",
      rows: 6,
    },
  ];

  const renderInputFields = () =>
    inputFields.map((field) => (
      <Inputfield
        key={field.name}
        label={field.label}
        name={field.name}
        type={field.type}
        value={formik.values[field.name]}
        onChngeterm={(e) => formik.setFieldValue(field.name, e.target.value)}
        error={formik.touched[field.name] && Boolean(formik.errors[field.name])}
        helperText={formik.touched[field.name] && formik.errors[field.name]}
        multiline={field.type === "textarea"}
        rows={field.rows}
      />
    ));

  return (
    <>
      <Box mt={7}>
        <Box
          pt={{ xs: 1, sm: 5, md: 20 }}
          pb={{ xs: 1, sm: 5, md: 10 }}
          sx={{
            backgroundImage: `url(${contact_us})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: { xs: "cover", md: "cover" },
            backgroundPosition: "center",
            width: "100%",
            height: { xs: "auto", sm: "auto", md: "10vh" },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              maxWidth: {
                xs: "100%",
                sm: "90%",
                md: "80%",
                lg: "80%",
                xl: "75%",
              },
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Stack direction="column">
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fontSize: { xs: "40px", md: "70px" },
                      color: "#1662A7",
                      lineHeight: "70px",
                    }}
                  >
                    Contact
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box pt={8}>
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: {
              xs: "100%",
              sm: "90%",
              md: "80%",
              lg: "80%",
              xl: "75%",
            },
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} align="center" pb={6}>
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Roboto",
                  lineHeight: { xs: "35px", md: "40px" },
                  fontWeight: 450,
                  color: "#25B2E8",
                }}
              >
                We are ready to help you sign up or give you support.
              </Typography>
            </Grid>

            <Grid
              xs={12}
              md={12}
              align="center"
              sx={{ display: { xs: "none", sm: "block", md: "block" } }}
            >
              <Grid xs={12} md={12}>
                <div
                  style={{
                    marginLeft: "0px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "50px",
                    alignItems: "center",
                  }}
                >
                  {/* Contact Sales Section */}
                  <div style={{ textAlign: "left" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "Roboto",
                        lineHeight: "45px",
                        fontWeight: "bold",
                        fontSize: { xs: "15px", md: "33px" },
                        color: "#1662A7",
                      }}
                    >
                      Contact Sales
                    </Typography>

                    <form onSubmit={formik.handleSubmit}>
                      <Card
                        sx={{
                          mt: 2,
                          backgroundColor: "#F3F4F6",
                          border: "1px solid #B8C2CC",
                          boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
                          borderRadius: "20px",
                        }}
                      >
                        <CardContent>
                          <Stack pt={1.5} spacing={2}>
                            <Stack
                              direction={{ xs: "column", md: "row" }}
                              spacing={3}
                            >
                              {renderInputFields().slice(0, 2)}
                            </Stack>

                            {renderInputFields().slice(2, 3)}
                            {renderInputFields().slice(3, 4)}

                            <Stack
                              direction={{ xs: "column", md: "row" }}
                              spacing={2}
                            >
                              {renderInputFields().slice(4, 5)}
                              {renderInputFields().slice(5, 6)}
                            </Stack>

                            {renderInputFields().slice(6)}

                            <Button
                              type="submit"
                              sx={{
                                backgroundColor: "#25B2E8",
                                fontFamily: "Roboto",
                                height: "45px",
                                borderRadius: "10px",
                                fontSize: "18px",
                                color: "white",
                                textTransform: "capitalize",
                                alignSelf: "center",
                                width: "180px",
                              }}
                            >
                              Submit
                            </Button>
                          </Stack>
                        </CardContent>
                      </Card>
                    </form>
                  </div>

                  {/* Contact Support Section */}
                  <div style={{ textAlign: "left" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "Roboto",
                        lineHeight: "45px",
                        fontWeight: "bold",
                        fontSize: { xs: "15px", md: "33px" },
                        color: "#1662A7",
                      }}
                    >
                      Contact Support
                    </Typography>

                    <Card
                      sx={{
                        mt: 2,
                        backgroundColor: "#F3F4F6",
                        border: "1px solid #B8C2CC",
                        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "20px",
                      }}
                    >
                      <CardContent>
                        <Stack spacing={2} alignItems="center">
                          <Box
                            component="img"
                            src={it_support}
                            sx={{ width: "100%", height: "448px" }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: "Roboto",
                              fontWeight: "medium",
                              fontSize: { xs: "15px", md: "23px" },
                              color: "#6E6B7B",
                              textAlign: "center",
                            }}
                          >
                            Click below to reach out to our team
                          </Typography>

                          <Button
                            sx={{
                              backgroundColor: "#25B2E8",
                              fontFamily: "Roboto",
                              height: "45px",
                              borderRadius: "10px",
                              fontSize: "18px",
                              color: "white",
                              textTransform: "capitalize",
                              width: "180px",
                            }}
                          >
                            Contact Support
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              md={12}
              align="center"
              sx={{ display: { xs: "block", sm: "none", md: "none" } }}
            >
              <Grid xs={12} md={12}>
                {/* <div style={{ marginLeft: "0px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "50px", alignItems: "center" }}> */}
                {/* Contact Sales Section */}
                <div style={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "Roboto", lineHeight: "45px" }}
                    fontWeight="medium"
                    fontSize={{ xs: "25px", md: "33px" }}
                    color="#1662A7"
                    align="left" // Align text to the left
                  >
                    Contact Sales
                  </Typography>

                  <form onSubmit={formik.handleSubmit}>
                    <Card
                      sx={{
                        mt: 2,
                        backgroundColor: "#F3F4F6",
                        border: "1px solid #B8C2CC",
                        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
                        borderRadius: "20px",
                      }}
                    >
                      <CardContent>
                        <Stack pt={1.5} spacing={2}>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={3}
                          >
                            {renderInputFields().slice(0, 2)}
                          </Stack>

                          {renderInputFields().slice(2, 3)}
                          {renderInputFields().slice(3, 4)}

                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={2}
                          >
                            {renderInputFields().slice(4, 5)}
                            {renderInputFields().slice(5, 6)}
                          </Stack>

                          {renderInputFields().slice(6)}

                          <Button
                            type="submit"
                            sx={{
                              backgroundColor: "#25B2E8",
                              fontFamily: "Roboto",
                              height: "45px",
                              borderRadius: "10px",
                              fontSize: "18px",
                              color: "white",
                              textTransform: "capitalize",
                              alignSelf: "center",
                              width: "180px",
                            }}
                          >
                            Submit
                          </Button>
                        </Stack>
                      </CardContent>
                    </Card>
                  </form>
                </div>

                {/* Contact Support Section */}
                <div style={{ textAlign: "left" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "Roboto", lineHeight: "45px" }}
                    fontWeight="medium"
                    fontSize={{ xs: "25px", md: "33px" }}
                    color="#1662A7"
                    align="left" // Align text to the left
                    mt={2}
                  >
                    Contact Support
                  </Typography>

                  <Card
                    sx={{
                      mt: 2,
                      backgroundColor: "#F3F4F6",
                      border: "1px solid #B8C2CC",
                      boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "20px",
                    }}
                  >
                    <CardContent>
                      <Stack spacing={2} alignItems="center">
                        <Box
                          component="img"
                          src={it_support}
                          sx={{ width: "100%", height: "448px" }}
                        />
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "medium",
                            fontSize: { xs: "15px", md: "23px" },
                            color: "#6E6B7B",
                            textAlign: "center",
                          }}
                        >
                          Click below to reach out to our team
                        </Typography>

                        <Button
                          sx={{
                            backgroundColor: "#25B2E8",
                            fontFamily: "Roboto",
                            height: "45px",
                            borderRadius: "10px",
                            fontSize: "18px",
                            color: "white",
                            textTransform: "capitalize",
                            width: "180px",
                          }}
                        >
                          Contact Support
                        </Button>
                      </Stack>
                    </CardContent>
                  </Card>
                </div>
                {/* </div> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            pt={{ xs: 3, md: 7 }}
            pb={{ xs: 0, md: 6 }}
            align="center"
          >
            <Textfield />
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Contactus;

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../styles/Home.css";
import text from "../../Assets/text.png";
import signature from "../../Assets/signature.png";
import initials from "../../Assets/initials.png";

import date from "../../Assets/date.png";
import checkmark from "../../Assets/checkmark.png";

import stamp from "../../Assets/stamp.png";
import driving_license from "../../Assets/driving_license.png";
import password_photo from "../../Assets/password_photo.png";

import dropdown from "../../Assets/dropdown.png";

import send_document from "../../Assets/send_document.png";

import coming_soon from "../../Assets/coming_soon.png";
import secure from "../../Assets/secure.png";
import Textfield from "../Textfield";

function Functions() {
  const functionalities = [
    { src: text, label: "Text" },
    { src: signature, label: "Signature" },
    { src: initials, label: "Initials" },
    { src: date, label: "Date" },
    { src: checkmark, label: "Checkmark" },
    { src: stamp, label: "Stamp" },
    { src: driving_license, label: "Driving License" },
    { src: password_photo, label: "Passport Photo" },
    { src: dropdown, label: "Dropdown" },
  ];

  const gridSettings = { xs: 12, md: 4 };

  return (
    <Box pt={3} pb={3}>
      <Container
        maxWidth="lg"
        sx={{ maxWidth: { xs: "100%", sm: "90%", md: "80%", xl: "75%" } }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} align="center" pb={5}>
            <Typography
              sx={{
                width: "90%",
                fontFamily: "Roboto",
                lineHeight: "45px",
                fontWeight: "medium",
                fontSize: { xs: "22px", md: "40px" },
                color: "#1662A7",
              }}
            >
              Functions
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {functionalities.map(({ src, label }, index) => (
              <Grid item {...gridSettings} key={index} align="center">
                <Box
                  sx={{
                    boxShadow: "none",
                    width: "80%",
                    borderRadius: "10px",
                    border: "1px solid #B8C2CC",
                  }}
                >
                  <Stack direction="row" p={1} spacing={4} alignItems="center">
                    <Box
                      component="img"
                      src={src}
                      alt={label}
                      sx={{ width: "15%" }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: "medium",
                        fontSize: { xs: "14px", md: "20px" },
                        color: "#6E6B7B",
                      }}
                    >
                      {label}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container spacing={0} pt={{ xs: 5, md: 0 }}>
          <Grid item xs={12} align="center">
            <Box
              component="img"
              src={send_document}
              sx={{ width: { xs: "100%", md: "95%" } }}
            />
          </Grid>

          <Grid item xs={12} md={6} pt={10} align="left">
            <Box
              component="img"
              src={coming_soon}
              sx={{ width: { xs: "100%", md: "80%" } }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            pt={{ xs: 2, md: 5 }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              spacing={{ xs: 1, md: 4 }}
              sx={{ width: { xs: "100%", md: "90%" } }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  lineHeight: { xs: "32px", md: "40px" },
                  fontWeight: "normal",
                  fontSize: { xs: "15px", md: "25px" },
                  color: "#6E6B7B",
                }}
              >
                For{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#1662A7",
                    textTransform: "capitalize",
                  }}
                >
                  DEVELOPERS
                </span>{" "}
                and{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#1662A7",
                    textTransform: "capitalize",
                  }}
                >
                  COMPANIES
                </span>{" "}
                requiring e-signatures embedded on company web pages or other
                advanced functions to enhance your workflow,
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Avatar
                  variant="square"
                  src={secure}
                  sx={{ width: { xs: 20, md: 30 }, height: { xs: 25, md: 37 } }}
                />
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    lineHeight: "40px",
                    fontSize: { xs: "15px", md: "25px" },
                    color: "#6E6B7B",
                  }}
                >
                  we will be ready by&nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#1662A7",
                      textTransform: "capitalize",
                    }}
                  >
                    MARCH 2025.
                  </span>
                </Typography>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} align="center" pt={6} pb={6}>
            <Textfield />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Functions;

import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "../../styles/Home.css";
import resellers from "../../Assets/resellers.png";
import non_profits from "../../Assets/non_profits.png";
import partners from "../../Assets/partners.png";
import Textfield from "../Textfield";

function Partners() {
  const cardContent = [
    {
      image: resellers,
      title: "Resellers",
      description:
        "Our reseller program is one of the most generous in the industry. We enjoy enhancing the value of our partners’ product offerings, saving their clients money and rewarding partners for their introductions.",
      imageWidth: "286px",
      imageHeight: "286px",
      cardWidth: { xs: "100%", md: "44%" },
    },
    {
      image: non_profits,
      title: "Non-Profits",
      description:
        "We offer substantial discounts to non-profit organizations and have established partnerships to facilitate regular donations.\nChoose from a variety of donation options to meet your fundraising goals.",
      imageWidth: "332px",
      imageHeight: "286px",
      cardWidth: { xs: "100%", md: "44%" },
    },
  ];

  const renderCard = (card, isSmallScreen) => (
    <Card
      sx={{
        alignSelf: "center",
        height: { xs: "auto", md: "600px", lg: "650px" },
        width: card.cardWidth,
        backgroundColor: "#F3F4F6",
        border: "1px solid #B8C2CC",
        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "45px",
      }}
    >
      <CardContent>
        <Stack spacing={isSmallScreen ? 2 : 3.5}>
          <Box
            component="img"
            src={card.image}
            sx={{
              alignSelf: "center",
              width: card.imageWidth,
              height: card.imageHeight,
            }}
          />
          <Typography
            variant="paragraph"
            sx={{
              alignSelf: "center",
              fontFamily: "Roboto",
              lineHeight: "45px",
            }}
            fontWeight="bold"
            fontSize={{ xs: "15px", md: "35px" }}
            color="#25B2E8"
          >
            {card.title}
          </Typography>
          <Typography
            variant="paragraph"
            sx={{
              width: "90%",
              alignSelf: "center",
              fontFamily: "Roboto",
              lineHeight: "40px",
            }}
            fontWeight={400}
            fontSize={{ xs: "15px", md: "18px" }}
            color="#6E6B7B"
          >
            {card.description}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <>
      <Box mt={{ xs: 7, md: 7 }}>
        <Box
          pt={{ xs: 1, sm: 5, md: 20 }}
          pb={{ xs: 1, sm: 5, md: 10 }}
          sx={{
            backgroundImage: `url(${partners})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: { xs: "auto", sm: "auto", md: "10vh" },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              maxWidth: {
                xs: "100%",
                sm: "90%",
                md: "80%",
                lg: "80%",
                xl: "75%",
              },
            }}
          >
            <Grid container spacing={0}>
              <Grid xs={12} md={12}>
                <Stack direction="column">
                  <Typography
                    variant="paragraph"
                    align="center"
                    sx={{ fontFamily: "Roboto", lineHeight: "70px" }}
                    fontWeight="bold"
                    fontSize={{ xs: "40px", md: "70px" }}
                    color="#1662A7"
                  >
                    Partners
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box pt={7} pb={0}>
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: {
              xs: "100%",
              sm: "90%",
              md: "80%",
              lg: "80%",
              xl: "75%",
            },
          }}
        >
          <Grid container spacing={0}>
            {/* Large screens */}
            <Grid
              xs={12}
              md={12}
              align="center"
              sx={{ display: { xs: "none", sm: "block", md: "block" } }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "70px",
                }}
              >
                {cardContent.map((card) => renderCard(card, false))}
              </div>
            </Grid>

            {/* Small screens */}
            <Grid
              xs={12}
              md={12}
              align="center"
              sx={{ display: { xs: "block", sm: "none", md: "none" } }}
            >
              <Stack direction="column" spacing={5}>
                {cardContent.map((card) => renderCard(card, true))}
              </Stack>
            </Grid>

            {/* Textfield */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              pt={{ xs: 3, md: 7 }}
              pb={{ xs: 0, md: 6 }}
              align="center"
            >
              <Textfield />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Partners;

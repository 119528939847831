import { Avatar, Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import "../../styles/Home.css";
import checkmark_icon from "../../Assets/checkmark_icon.png";
import DataCenters from "./Datacenters";
import security_and_compliance from "../../Assets/security_and_compliance.png";

const securityMeasures = [
  {
    title: "Robust data encryption",
    description:
      "We use industry-standard encryption protocols to protect your sensitive data.",
  },
  {
    title: "Secure data storage",
    description:
      "Our data centers adhere to strict security standards and are regularly audited.",
  },
  {
    title: "Regular security assessments",
    description:
      "We conduct ongoing security reviews to identify and address potential vulnerabilities.",
  },
  {
    title: "Compliance with industry standards",
    description:
      "We comply with relevant data privacy and security regulations, such as GDPR, CCPA, and HIPAA, among others.",
  },
];

const complianceEfforts = [
  {
    title: "Your digital signatures are legally binding",
    description:
      "Our solutions meet the requirements for legally enforceable digital signatures in various jurisdictions.",
  },
  {
    title: "Your data is handled responsibly",
    description: "We adhere to data privacy laws and regulations.",
  },
  {
    title: "Your business operations are protected",
    description:
      "We help you mitigate risks associated with document fraud and unauthorized access.",
  },
];

const Section = ({ title, items }) => (
  <Box pt={5} pb={4}>
    <Container
      maxWidth="lg"
      sx={{
        maxWidth: { xs: "100%", sm: "90%", md: "80%", lg: "80%", xl: "75%" },
      }}
    >
      <Typography
        variant="h6"
        fontWeight="medium"
        fontSize={{ xs: "20px", md: "30px" }}
        color="#25B2E8"
        mb={2}
      >
        {title}
      </Typography>
      {items.map((item, index) => (
        <Stack key={index} direction="row" spacing={2} alignItems="flex-start">
          <Avatar src={checkmark_icon} sx={{ pt: 2, width: 25, height: 25 }} />
          <Typography
            fontWeight="medium"
            fontSize={{ xs: "15px", md: "20px" }}
            color="#020202"
            sx={{ lineHeight: { xs: "35px", md: "45px" } }}
          >
            {item.title}:{" "}
            <span style={{ fontWeight: "normal", color: "#6E6B7B" }}>
              {item.description}
            </span>
          </Typography>
        </Stack>
      ))}
    </Container>
  </Box>
);

function SecurityCompliance() {
  return (
    <>
      <Box mt={7}>
        <Box
          pt={{ xs: 1, sm: 5, md: 20 }}
          pb={{ xs: 1, sm: 5, md: 10 }}
          sx={{
            backgroundImage: `url(${security_and_compliance})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            height: { xs: "auto", md: "10vh" },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              maxWidth: {
                xs: "100%",
                sm: "90%",
                md: "80%",
                lg: "80%",
                xl: "75%",
              },
            }}
          >
            <Typography
              align="center"
              fontWeight="bold"
              fontSize={{ xs: "40px", md: "64px" }}
              color="#1662A7"
              sx={{ lineHeight: "70px", fontFamily: "Roboto" }}
            >
              Security and Compliance
            </Typography>
          </Container>
        </Box>
      </Box>
      <Box pt={5} pb={4}>
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: {
              xs: "100%",
              sm: "90%",
              md: "80%",
              lg: "80%",
              xl: "75%",
            },
          }}
        >
          <Typography
            sx={{
              lineHeight: { xs: "35px", md: "45px" },
              fontFamily: "Roboto",
            }}
            fontWeight="normal"
            fontSize={{ xs: "15px", md: "20px" }}
            color="#6E6B7B"
            mb={2}
          >
            RequireSign is committed to providing a secure and compliant digital
            signature solution. We leverage state-of-the-art technology,
            including blockchain, to ensure the integrity and authenticity of
            your documents.
          </Typography>
        </Container>
      </Box>
      <Section
        title="Our security measures include:"
        items={securityMeasures}
      />
      <Section
        title="Our compliance efforts ensure that:"
        items={complianceEfforts}
      />
      <DataCenters />
    </>
  );
}

export default SecurityCompliance;

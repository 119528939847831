import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import "../../styles/Navbar.css";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import logo from "../../Assets/logo.png";
import url from "../../calendly_url";
import { login, try_for_free } from "../../url";

const drawerWidth = "100%";

const NAV_ITEMS = [
  { text: "Home", path: "/" },
  { text: "About", path: "/aboutus" },
  { text: "Features", path: "/features" },
  { text: "Security & Compliance", path: "/security" },
  { text: "Pricing Plans", path: "/pricings" },
  { text: "Partners", path: "/patners" },
  { text: "Contact", path: "/contact" },
];

const buttonStyle = {
  color: "#FFFFFF",
  fontFamily: "Roboto",
  height: "45px",
  borderRadius: "10px",
  fontSize: "15px",
  textTransform: "capitalize",
  alignSelf: "center",
  width: "130px",
};

function Navbar() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(false); // Close the drawer after navigation
  };

  return (
    <>
      <Box
        sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
      >
        <AppBar
          position="fixed"
          sx={{
            height: "80px",
            display: "flex",
            boxShadow: "0px 2px 4px rgba(22, 98, 167, 0.2)",
            backgroundColor: "white",
          }}
          className="navbar"
        >
          <Container maxWidth="lg">
            <Toolbar>
              <Grid container spacing={0}>
                <Grid item md={2.5} lg={2}>
                  <Box pt={1.5}>
                    {/* <Avatar variant="square" src={logo} sx={{ width: 20, height: 20 }} /> */}
                    <NavLink to="/">
                      <Box
                        component="img"
                        src={logo}
                        sx={{ pt: 0.5, width: 170, height: 40 }}
                      />
                    </NavLink>
                  </Box>
                </Grid>

                <Grid item md={6.5} lg={7} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={{ md: 1.5, lg: 0.7 }}
                      fontFamily="Roboto"
                      sx={{
                        pt: 3.5,
                        color: "white",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontWeight: "medium",
                        cursor: "pointer",
                      }}
                    >
                      {NAV_ITEMS.map(({ text, path }, index) => (
                        <React.Fragment key={index}>
                          <NavLink
                            to={path}
                            style={({ isActive }) => ({
                              textDecoration: "none",
                              color: isActive ? "#1662A7" : "#6E6B7B",
                              height: "18px",
                              borderBottom: isActive
                                ? "1px solid #1662A7"
                                : "none",
                              transition:
                                "color 0.3s ease, borderBottom 0.3s ease",
                            })}
                          >
                            <Typography
                              variant="paragraph"
                              sx={{ ":hover": { color: "#1662A7" } }}
                            >
                              {text}
                            </Typography>
                          </NavLink>
                          {index < NAV_ITEMS.length - 1 && (
                            <Typography
                              variant="paragraph"
                              sx={{ color: "#6E6B7B", fontWeight: "medium" }}
                            >
                              |
                            </Typography>
                          )}
                        </React.Fragment>
                      ))}
                    </Stack>
                  </div>
                </Grid>

                <Grid item md={3} lg={3} align="right">
                  <Stack direction="row" spacing={2} pt={1.5} align="right">
                    <a
                      href={try_for_free}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <Button
                        sx={{
                          backgroundColor: "#25B2E8",
                          ...buttonStyle,
                          "&:hover": {
                            backgroundColor: "#25B2E8",
                          },
                        }}
                      >
                        Try for FREE
                      </Button>
                    </a>
                    <a href={login} target="_blank" rel="noreferrer">
                      <Button
                        sx={{
                          backgroundColor: "#1662A7",
                          ...buttonStyle,
                          "&:hover": {
                            backgroundColor: "#1662A7",
                          },
                        }}
                      >
                        Login
                      </Button>
                    </a>
                  </Stack>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>

      <Box
        sx={{ display: { xs: "block", sm: "block", md: "none", lg: "none" } }}
      >
        <AppBar
          position="fixed"
          sx={{ backgroundColor: "white", boxShadow: "none" }}
        >
          <Toolbar>
            <Grid container spacing={2} pt={1}>
              <Grid item xs={9} sm={9}>
                <NavLink to="/">
                  <Box
                    component="img"
                    src={logo}
                    sx={{ width: 140, height: 30 }}
                  />
                </NavLink>
              </Grid>
              <Grid item xs={3} sm={3} align="right">
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon sx={{ color: "#1662A7" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              backgroundColor: "#ffffff",
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          anchor="top"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Container>
            <Stack
              direction="column"
              spacing={1}
              sx={{ pl: 2, pt: 3, pb: 3, fontSize: "15px", fontWeight: "bold" }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <NavLink to="/">
                  <Box
                    component="img"
                    src={logo}
                    sx={{ width: 140, height: 30 }}
                  />
                </NavLink>
                <Close
                  sx={{ color: "#1662A7" }}
                  onClick={() => setOpen(false)}
                />
              </Box>

              {/* Map through NAV_ITEMS and render each link */}
              {NAV_ITEMS.map(({ text, path }, index) => (
                <NavLink
                  to={path}
                  key={index}
                  style={({ isActive }) => ({
                    textDecoration: "none",
                    width: isActive ? "fit-content" : "100%",
                    padding: "10px 0",
                    color: isActive ? "#1662A7" : "#6E6B7B",
                    borderBottom: isActive ? "1px solid #1662A7" : "none",
                    "&:hover": { color: "#1662A7" },
                  })}
                  onClick={() => handleNavigation(path)}
                >
                  <Typography variant="paragraph" pt={1}>
                    {text}
                  </Typography>
                </NavLink>
              ))}

              <Stack direction="row" spacing={2} pt={3} justifyContent="center">
                <a
                  href={try_for_free}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    sx={{
                      backgroundColor: "#25B2E8",
                      ...buttonStyle,
                      "&:hover": { backgroundColor: "#25B2E8" },
                    }}
                  >
                    Try for FREE
                  </Button>
                </a>
                <a
                  href={login}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <Button
                    sx={{
                      backgroundColor: "#1662A7",
                      ...buttonStyle,
                      "&:hover": { backgroundColor: "#1662A7" },
                    }}
                  >
                    Login
                  </Button>
                </a>
              </Stack>
            </Stack>
          </Container>
        </Drawer>
      </Box>
    </>
  );
}

export default Navbar;
